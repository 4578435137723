/* eslint-disable indent */
import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DayPicker } from 'react-day-picker';

import { Loader } from '../../../components/Loader';
import { CustomCaption } from '../../../components/CustomCaption';
import { PopoverCalendar } from '../../../components/PopoverCalendar';

import { useProviderSlots } from '../api/queries/useProviderSlots';

import { onImageError } from '../../../utils/image';

import { ProviderSlotType } from '../../../@types/api';
import { ProviderDialogType } from '../../../@types/providerDialog';
import { Link } from 'react-router-dom';

type SignupDialogProviderProps = {
  dialogData: ProviderDialogType;
  handleChooseSlot: (slot: ProviderSlotType) => void;
};

export const SignupDialogProvider: FC<SignupDialogProviderProps> = ({
  dialogData,
  handleChooseSlot,
}) => {
  const soonestAvailableDate = dialogData.provider.availableSlots.length
    ? new Date(
        dayjs
          .utc(dialogData.provider.availableSlots[0].startDate)
          .tz('PST')
          .format('YYYY-MM-DDTHH:mm:ss'),
      )
    : new Date(
        dayjs
          .utc(new Date())
          .add(2, 'days')
          .tz('PST')
          .format('YYYY-MM-DDTHH:mm:ss'),
      );

  const lastAvailableDate = dialogData.provider.availableSlots.length
    ? new Date(
        dayjs
          .utc(dialogData.provider.availableSlots.at(-1)?.endDate)
          .tz('PST')
          .format('YYYY-MM-DDTHH:mm:ss'),
      )
    : new Date(
        dayjs
          .utc(new Date())
          .add(4, 'days')
          .tz('PST')
          .format('YYYY-MM-DDTHH:mm:ss'),
      );

  const transformSlotsData = (slots: ProviderSlotType[]) => {
    return slots.reduce<Record<string, ProviderSlotType[]>>((prev, curr) => {
      const date = dayjs.utc(curr.startDate).tz('PST').format('MM/DD/YYYY');
      prev[date] = [...(prev[date] || []), curr];

      return prev;
    }, {});
  };

  const [slots, setSlots] = useState(
    transformSlotsData(dialogData.provider.availableSlots),
  );
  const [expand, setExpand] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [calendarDate, setCalendarDate] = useState<Date | undefined>(
    soonestAvailableDate,
  );
  const [dateRange, setDateRange] = useState<{
    minValue: Date;
    maxValue: Date;
    startDate: Date;
    endDate: Date;
  }>({
    minValue: soonestAvailableDate,
    maxValue: lastAvailableDate,
    startDate: soonestAvailableDate,
    endDate: lastAvailableDate,
  });

  const {
    isSuccess: isProviderSlotsSuccess,
    isFetching: isProviderSlotsFetching,
    isError: isProviderSlotsError,
    error: providerSlotsError,
    data: providerSlots,
  } = useProviderSlots({
    state: dialogData.state,
    insurance: dialogData.insurance,
    condition: dialogData.condition,
    admissible: dialogData.typeOfSession,
    gender: dialogData.gender,
    language: dialogData.language,
    providerId: dialogData.provider.id,
    appointmentTypeId: dialogData.appointmentId,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : undefined,
  });

  const maxColumns = useMemo(() => {
    return Object.entries(slots).length;
  }, [slots]);

  const maxRows = useMemo(() => {
    const lengths = Object.values(slots).map((arrs) => arrs.length);
    const maxLength = Math.max(...lengths);

    return maxLength;
  }, [slots]);

  const handleChangeCalendarDate = (date: Date | undefined) => {
    if (date) {
      setCalendarDate(date);
      setStartDate(date);
      setDateRange((prev) => ({
        minValue: prev.minValue,
        maxValue: prev.maxValue,
        startDate: dayjs(date).toDate(),
        endDate: dayjs(date).add(4, 'days').toDate(),
      }));
    }
  };

  const handleChangeDateRange = (next?: boolean) => {
    if (next) {
      const newStartDate = dayjs(dateRange.endDate).add(1, 'days').toDate();

      setCalendarDate(newStartDate);
      setStartDate(newStartDate);
      setDateRange((prev) => ({
        minValue: prev.minValue,
        maxValue: prev.maxValue,
        startDate: newStartDate,
        endDate: dayjs(prev.endDate).add(5, 'days').toDate(),
      }));
    } else {
      const newStartDate = dayjs(dateRange.startDate)
        .subtract(5, 'days')
        .toDate();
      const minValue = dateRange.minValue;

      if (newStartDate >= minValue) {
        setCalendarDate(newStartDate);
        setStartDate(newStartDate);
        setDateRange((prev) => ({
          minValue: prev.minValue,
          maxValue: prev.maxValue,
          startDate: newStartDate,
          endDate: dayjs(prev.endDate).subtract(5, 'days').toDate(),
        }));
      } else {
        setCalendarDate(minValue);
        setStartDate(minValue);
        setDateRange((prev) => ({
          minValue: prev.minValue,
          maxValue: prev.maxValue,
          startDate: minValue,
          endDate: dayjs(prev.maxValue).toDate(),
        }));
      }
    }
  };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  useEffect(() => {
    if (isProviderSlotsSuccess && providerSlots.data) {
      const transformedData = transformSlotsData(providerSlots.data);

      setSlots(transformedData);
    }
  }, [isProviderSlotsSuccess, providerSlots]);

  return (
    <div className="providerDialog">
      <>
        <div className="providerDialog__info">
          <div className="providerDialog__card">
            <div className="providerDialog__photo">
              <img
                src={dialogData.provider.photo}
                alt={dialogData.provider.firstName}
                className="providerDialog__img"
                onError={onImageError}
              />
            </div>
            <p className="providerDialog__name">
              {dialogData.provider.firstName} {dialogData.provider.lastName}
            </p>
          </div>
          {dialogData.provider.about && (
            <div className="providerDialog__about">
              <h2 className="providerDialog__title">About Me</h2>
              <p className="providerDialog__text">
                {dialogData.provider.about
                  .replaceAll('<p>', '')
                  .replaceAll('</p>', '')
                  .replaceAll('<b>', '')
                  .replaceAll('</b>', '')
                  .slice(
                    0,
                    dialogData.provider.about.length > 300 && !expand
                      ? 300
                      : dialogData.provider.about.length,
                  )}{' '}
                {dialogData.provider.about.length > 300 ? (
                  <button
                    type="button"
                    className="providerDialog__expand"
                    onClick={handleExpand}
                  >
                    {expand ? 'Show less' : 'Show more'}
                  </button>
                ) : null}
              </p>
            </div>
          )}
        </div>
        <div className="providerDialog__content">
          <h3 className="providerDialog__subTitle">
            Book a session with {dialogData.provider.firstName}{' '}
            {dialogData.provider.lastName}
            <div className="providerDialog__calendar--small">
              <PopoverCalendar
                date={calendarDate}
                setDate={handleChangeCalendarDate}
                soonestAvailableDate={soonestAvailableDate}
              />
            </div>
          </h3>
          <div className="providerDialog__week">
            {isProviderSlotsFetching ? (
              <Loader color="#2c6f54" width="16" height="16" />
            ) : (
              <>
                <button
                  type="button"
                  className="providerDialog__weekBtn"
                  aria-label="Prev week"
                  onClick={() => handleChangeDateRange(false)}
                  disabled={
                    dateRange.minValue.toString() ===
                    dateRange.startDate.toString()
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#chevronLeft" />
                  </svg>
                </button>
                <p className="providerDialog__weekText">
                  {dayjs(dateRange.startDate).format('DD')} -{' '}
                  {dayjs(dateRange.endDate).format('DD')}{' '}
                  {dayjs(dateRange.endDate).format('MMMM')}
                </p>
                <button
                  type="button"
                  className="providerDialog__weekBtn"
                  aria-label="Next week"
                  onClick={() => handleChangeDateRange(true)}
                >
                  <svg
                    width="20"
                    height="20"
                    focusable="false"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons.svg#chevronRight" />
                  </svg>
                </button>
              </>
            )}
          </div>
          <div className="providerDialog__container">
            {Object.entries(slots).length ? (
              <div
                className="providerDialog__wrapper scrollbar"
                style={{
                  overflowY: `${isProviderSlotsFetching ? 'hidden' : 'auto'}`,
                }}
              >
                <div
                  className="providerDialog__table"
                  style={{
                    gridTemplateColumns: `repeat(${maxColumns}, var(--slot-width))`,
                  }}
                >
                  <div
                    className="providerDialog__table providerDialog__table--absolute"
                    style={{
                      gridTemplateColumns: `repeat(${maxColumns}, var(--slot-width))`,
                    }}
                  >
                    {Array(maxColumns)
                      .fill(0)
                      .map((_, index) => (
                        <div className="providerDialog__column" key={index}>
                          <p className="providerDialog__day"></p>
                          <div
                            className="providerDialog__subColumn"
                            style={{
                              gridTemplateRows: `repeat(${maxRows}, 1fr)`,
                            }}
                          >
                            {Array(maxRows)
                              .fill(0)
                              .map((_, index) => (
                                <div
                                  className="providerDialog__slot"
                                  key={index}
                                ></div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                  {Object.entries(slots).map((slot, index) => (
                    <div className="providerDialog__column" key={index}>
                      <p className="providerDialog__day">
                        {dayjs(slot[0]).format('ddd')}
                        <span>{dayjs(slot[0]).format('DD')}</span>
                      </p>
                      <div
                        className="providerDialog__subColumn"
                        style={
                          index === 0
                            ? {
                                border: '1px solid #eeeeef',
                                borderTop: 'none',
                                gridTemplateRows: `repeat(${maxRows}, 1fr)`,
                              }
                            : {
                                borderBottom: '1px solid #eeeeef',
                                borderRight: '1px solid #eeeeef',
                                gridTemplateRows: `repeat(${maxRows}, 1fr)`,
                              }
                        }
                      >
                        {slot[1].map((item) => (
                          <div
                            className="providerDialog__slot"
                            key={item.startDate}
                          >
                            <button
                              type="button"
                              className="providerDialog__book"
                              onClick={() => handleChooseSlot(item)}
                            >
                              Book
                              <br />
                              <span className="providerDialog__time">
                                {dayjs
                                  .utc(item.startDate)
                                  .tz('PST')
                                  .format('hh:mm a')}{' '}
                                -{' '}
                                {dayjs
                                  .utc(item.endDate)
                                  .tz('PST')
                                  .format('hh:mm a')}
                              </span>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                {isProviderSlotsFetching && (
                  <div className="providerDialog__loader">
                    <Loader color="#ffffff" width="60" height="60" />
                  </div>
                )}
              </div>
            ) : (
              <Link
                className="request-time-link-signup"
                to={`/request-time?id=${dialogData.provider.id}`}
              >
                Request a Time with {dialogData.provider.firstName}
              </Link>
            )}
            <div className="providerDialog__calendar--big">
              <DayPicker
                mode="single"
                showOutsideDays
                weekStartsOn={1}
                className="calendar"
                selected={calendarDate}
                onSelect={handleChangeCalendarDate}
                fromDate={soonestAvailableDate}
                components={{
                  Caption: CustomCaption,
                }}
              />
            </div>
          </div>
          {isProviderSlotsError && providerSlotsError.response && (
            <p>
              {providerSlotsError.response.data.message ||
                providerSlotsError.message}
            </p>
          )}
        </div>
      </>
    </div>
  );
};
